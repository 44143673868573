<template>
	<nav class="breadcrumb">
		<ol>
			<li>
				<router-link class="inactive-link" exact-active-class="active-link"
				             :to="{name: 'Home'}">{{$t('home')}}</router-link>
			</li>
			<li>
				<router-link class="inactive-link" exact-active-class="active-link"
				             :to="{name: 'Shop', params: {category: getCategoryId}}">{{ $t(getCategory.name) }}</router-link>
			</li>
      <li v-if="product && product.name">
        <router-link class="inactive-link" exact-active-class="active-link"
                     :to="{name: 'Product', params: {id: product.slug}}">{{ product.name }}</router-link>
      </li>
		</ol>
	</nav>
</template>

<script>
import {mapGetters} from "vuex";

export default {
	name: "Breadcrumb",
  computed: {
    ...mapGetters('category', ['getCategory', 'getCategoryId']),
  },
  mounted: function () {
    if (this.product && !this.getCategory) {
      this.$store.commit('category/setCategory', this.product.categ)
    }
  },
  watch: {
    product: function (newVal, oldVal) {
      if (this.product) {
        this.$store.commit('category/setCategory', this.product.categ)
      }
    }
  },
  props: {
    product: {
      type: [Object],
      required: false,
    },
  },
}
</script>

<style lang="scss" type="text/scss" scoped>
.breadcrumb {
	width: 100%;
	padding: 2vh 4%;
	margin: 0;

	ol {
		@include flex(flex-start, center, row, wrap, $gap: 5px 0);
		width: 100%;

		li {
			position: relative;

			&:not(:last-child)::after {
				content:'>'
			}

			&:last-child a {
				color: $LightBrown;
				pointer-events: none;
				padding-right: 0
			}

			&:first-child a {
				padding-left: 0
			}

			a {
				color: $Blue;
				padding: .25rem .5rem;
			}
		}
	}
}
</style>